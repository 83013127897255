// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.


import { useEffect, useRef, useState } from "react";
import { PlansActiveTasksPieChart } from "./PlansActiveTasksPieChart";
import { PlanStackedPlans } from "./PlanStackedPlans";
import { GanttPlan, GanttTask, Person } from "../../../../ganttmods/publictypes";
import { getFromLocalCache, log, saveToLocalCache } from "../../../helper";
import { PeopleTasks } from "./PeopleTasks";
import { PepleGraph } from "../../PeopleGraph";
import { TeamsFx } from "@microsoft/teamsfx";
import { UnassignedTasks } from "./UnassignedTasks";
import { Button, Datepicker, Dropdown, DropdownProps, Flex, Loader, Tooltip } from "@fluentui/react-northstar";
import { PlanDetails } from "./PlanDetails";
import { SyncIcon  } from '@fluentui/react-icons-northstar'

export function PortfolioDashboard(props: {allplans:GanttPlan[], getportfoliodashboardtaskdata: ((plan:GanttPlan)=>Promise<GanttTask[]>) | undefined, teamsfx?: TeamsFx}) 
{
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
    };
  const [dashboardplans, setDashboardplans] = useState<GanttPlan[]>([]);
  const [dashboardfilteredplans, setDashboardfilteredplans] = useState<GanttPlan[]>([]);
  const [loadingplanprogress, setLoadingplanprogress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [people, setPeople] = useState<Person[] | null>(null);
  const [clientWidth, setClientWidth] = useState<number>(1000);
  const [clientHeight, setClientHeight] = useState<number>(600);
  const [refreshingdata, setRefreshingdata] = useState<boolean>(false);
  const [datarefreshed, setDatarefreshed] = useState<boolean>(false);
  const [ownersfiler, setOwnersFilter] = useState<any[]>([]);
  const [plansfilter, setPlansFilter] = useState<any[]>([]);
  const [filterSelectedPlans, setFilterSelectedPlans] = useState<any[]>([]);
  const [filterSelectedOwners, setFilterSelectedOwners] = useState<any[]>([]);
  const [planselected, setPlanSelected] = useState<GanttPlan | null>(null);
  const [lastrefresehd, setLastRefreshed] = useState<string>(new Date(2024,0,1).toLocaleDateString());
  
  const wrapperRef = useRef<HTMLDivElement>(null);
  
  useEffect(()=>{ 
    window.addEventListener('resize', handleresize);
    setLoading(true);
    log('All Plans', props.allplans);
    
    let localplans = getFromLocalCache("dashboardplans");
    let lastrefresehd = getFromLocalCache("dashboardplanslastrefreshed");
    let lastrefresehdDate = new Date(lastrefresehd);
    setLastRefreshed(lastrefresehdDate.toLocaleDateString() + " " + lastrefresehdDate.toLocaleTimeString());
    
    if (localplans != undefined && localplans != null)
    {
      log('Local Dashboard Plans', localplans);
      setDashboardplans([...localplans]);
      setDashboardfilteredplans([...localplans]);
      setLoading(false);
      
    } else
    {
      refreshdata();
    }
    
    
  }, []);
  useEffect(()=>{
    log('Dashboard Plans', dashboardplans);
  }, [dashboardplans]);
  useEffect(()=>{
    log('Dashboard Filtered Plans', dashboardfilteredplans);
  }, [dashboardfilteredplans]);
  const refreshdata = async () => {
    setRefreshingdata(true);
    gettaskdata(props.allplans).then((populatedplans:GanttPlan[])=>
    {
      log('Populated Plans', populatedplans);
      setDashboardplans(populatedplans);
      setFilterSelectedPlans([]);
      setFilterSelectedOwners([]);
      setDashboardfilteredplans(populatedplans);
      saveToLocalCache("dashboardplans", populatedplans);
      saveToLocalCache("dashboardplanslastrefreshed", new Date().toISOString());
      setLastRefreshed(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString());
      log('Local Dashboard Plans Updated!', populatedplans);
      setLoading(false);
      setDatarefreshed(true);
      setRefreshingdata(false);
    }); 
    
  }
  useEffect(()=>{
    if (people != null)
    {
      let owners:any[] = [];
      let plansfilterlocal:any[] = [];
      props.allplans.forEach((p:GanttPlan) => {
        plansfilterlocal.push({key:p.planid, header:p.planName, content:p.ownerName});
        if (p.ownerId != undefined && p.ownerId != null && p.ownerId != "")
        {
          if (owners.findIndex(o=>o.key==p.ownerId) == -1)
            {
              let ownername = p.ownerName;
              if (ownername == undefined || ownername == null || ownername == "")
              {
                let person = people.find(pe=>pe.id == p.ownerId);
                if (person != undefined && person != null)
                {
                  ownername = person.header;
                }
              }
              owners.push({key:p.ownerId, header:ownername, content:ownername});
            }
        }
      });
      setOwnersFilter(owners);
      setPlansFilter(plansfilterlocal);
    }
  }, [people]);
 
  useEffect(()=>{//replaces owners and plans filter
    log('filterSelectedPlans', filterSelectedPlans);
    
    if (dashboardplans.length == 0) return;
    if (filterSelectedPlans.length > 0 || filterSelectedOwners.length > 0)
    {
      let filteredplans:GanttPlan[] = [];
      for (let i=0; i<dashboardplans.length; i++)
      {
        let plan = dashboardplans[i];
        if ((filterSelectedPlans.length == 0 || filterSelectedPlans.findIndex(p=>p.key == plan.planid) != -1) && 
            (filterSelectedOwners.length == 0 || filterSelectedOwners.findIndex(o=>o.key == plan.ownerId) != -1))
        {
          filteredplans.push(plan);
        }
      }
      
      setDashboardfilteredplans([]);
      setTimeout(()=>setDashboardfilteredplans(filteredplans), 100);
      log('Filtered Plans', filteredplans);
    } else
    {
      setDashboardfilteredplans([]);
      setTimeout(()=>setDashboardfilteredplans(dashboardplans), 100);;
    }
  }, [filterSelectedPlans, filterSelectedOwners]);

  useEffect(()=>{
    handleresize();
  }, [loading]);

  const handleresize = ():void => {
    log('Wrapper Width, Height', wrapperRef.current?.clientWidth, wrapperRef.current?.clientHeight);
    if (wrapperRef.current == null || wrapperRef.current == undefined || wrapperRef.current?.clientWidth == undefined)
      {
        if (clientWidth > 0 && clientHeight > 0)
        {
          return;
        }
      } 
    if (wrapperRef.current && wrapperRef.current.clientWidth > 600)
    {
      setClientWidth(wrapperRef.current.clientWidth);
    } else
    {
      setClientWidth(600);
    }
    if (wrapperRef.current && wrapperRef.current.clientHeight > 500)
    {
      setClientHeight(wrapperRef.current.clientHeight);
    } else
    {
      setClientHeight(500);
    }
  }
  
  const gettaskdata = async (plans:GanttPlan[]):Promise<GanttPlan[]> => 
  {
    let populatedtasks:GanttPlan[] = [];
    setLoadingplanprogress(0);
    if (props.getportfoliodashboardtaskdata)
      {
        for (let i = 0; i < plans.length; i++)
        {
          //log("Loading Dashboard Plans(" + plans.length + "/" + i + ")");
          setLoadingplanprogress(i+1);
          let tsks = await props.getportfoliodashboardtaskdata(plans[i]);
          populatedtasks.push({...plans[i], tasks:tsks});
        }
      }
      return populatedtasks;
  }

  return (
    <>
      
      {(loading) && <Flex vAlign="center" hAlign="center" style={{width:'95vw', height:'95vh'}}><Loader label={ 'Loading Plans ' + loadingplanprogress + "/" + props.allplans.length} /></Flex>}
      {(!loading) && 
        <>
          
            {planselected == null?
            <>
              <Flex gap="gap.small" padding="padding.medium" style={{ width:'100vw'}} hAlign="center" vAlign="center">
                <Dropdown
                  multiple
                  items={ownersfiler}
                  placeholder="Select owners"
                  noResultsMessage="We couldn't find any owners."
                  a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                  value={filterSelectedOwners}
                  onChange={(e, a:DropdownProps)=>
                    {
                      if (a.value != undefined)
                      {
                        setFilterSelectedOwners(a.value as any[]);
                      }
                    }}
                />
                <Dropdown
                  multiple
                  search
                  renderSelectedItem={(SelectedItem, props) => (
                    <SelectedItem {...props} header={`${props.header} (selected)`} />
                  )}
                  items={plansfilter}
                  placeholder="Select plans"
                  noResultsMessage="We couldn't find any plans."
                  a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                  value={filterSelectedPlans}
                  onChange={(e, a:DropdownProps)=>
                    {
                      if (a.value != undefined)
                      {
                        setFilterSelectedPlans(a.value as any[]);
                      }
                    }}
                />
                <Datepicker
                  today={new Date()}
                  inputPlaceholder="Filter tasks by date"
                  input={{clearable: true,}}
                 />
                 <div >
                    <Tooltip content={"Last refreshed " + lastrefresehd} pointing trigger=
                    {
                      <Button onClick={()=>{setLoading(true);refreshdata();}} icon={<SyncIcon />} iconOnly={!refreshingdata} disabled={refreshingdata} >{ !refreshingdata?'':'Refreshing ' + loadingplanprogress + "/" + props.allplans.length}</Button>
                    }></Tooltip>
                    
                  </div>
              </Flex>
              {dashboardfilteredplans.length == 0?
              <></>
              :
              <Flex ref={wrapperRef} gap="gap.small" padding="padding.medium" style={{overflow:'hidden', width:'100vw', height:'calc(100vh - 70px)'}}>
                <Flex.Item size="size.medium">
                  <PlanStackedPlans dashboardplans={dashboardfilteredplans} people={people?people:[]} width={clientWidth*0.4} height={clientHeight*0.9} planselected={(o)=>{setPlanSelected(o);}} />
                </Flex.Item>

                <Flex.Item size="size.medium">
                  <PeopleTasks dashboardplans={dashboardfilteredplans} people={people?people:[]} width={clientWidth*0.4} height={clientHeight*0.9} />
                </Flex.Item>

                <Flex.Item size="size.small">
                  <UnassignedTasks dashboardplans={dashboardfilteredplans} people={people?people:[]} width={clientWidth*0.1} height={clientHeight*0.9} />
                </Flex.Item>
              </Flex>}
            </>
            
            :
            
              <PlanDetails plan={planselected} people={people?people:[]} width={clientWidth} height={clientHeight} teamsfx={teamsfx} close={()=>{setPlanSelected(null);}}/>
            
            }
          
          
        </>
      }
      
        
     
      {people == null && 
        <div style={{position:'absolute', left:'45vw', top:'40vh', paddingTop:40, paddingBottom:20, paddingLeft:20, paddingRight:20, backgroundColor:'#e4e4e4', borderRadius:5}}>
          <PepleGraph peopleloaded={(people:Person[])=>
                    {
                        setPeople(people);
                    }} teamsfx={teamsfx} askforpermissions={true} />
        </div>
      }
    </>
  );
}
