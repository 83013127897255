import { useContext, useEffect, useState } from "react";
import { Button, Image, Menu, ParticipantRemoveIcon, TeamCreateIcon, Tooltip } from "@fluentui/react-northstar";
import "./Playground.css";
import { EditCode } from "../../authentication/EditCode";
import { Deploy } from "../../authentication/Deploy";
import { Publish } from "../../authentication/Publish";
import { AddSSO } from "../../authentication/AddSSO";
import { PlannerPPM } from "./PlannerPPM";
import {BrowserView, MobileView, TabletView} from 'react-device-detect';

import "../../../index.css";
import { PlannerPPMMobile } from "../mobile/PlannerPPMMobile";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsFxContext } from "../../Context";
import { log } from "../../helper";
import { AssignLicenses } from "../../subscription/AssignLicenses";


export function Playground(props: { environment?: string }) {
  const { environment } = {
    environment: window.location.hostname === "localhost" ? "local" : "azure",
    ...props,
  };
  const { themeString } = useContext(TeamsFxContext);
  const [userlicensed, setUserlicensed] = useState<boolean>(true);
  const [licensenavigated, setLicensenavigated] = useState<boolean>(false);
  const [usermail, setusermail] = useState<string>('');
  const [orgid, setorgid] = useState<string>('');
  const [useradmin, setUseradmin] = useState<boolean>(false);
  const [navigatedToPlan, setNavigatedToPlan] = useState<boolean>(false);
  const [hostname, setHostname] = useState<string>('');
  const [clientType, setClientType] = useState<string>('');
  const [ismobile, setIsMobile] = useState<boolean | undefined>(undefined);

  useEffect(()=>{
    log("color theme", themeString);
    document.body.style.backgroundColor = themeString === "default" ? "#eeeeee" : "rgb(45, 44, 44)";
  }, [themeString]);
  useEffect(()=>{

    /* Dont remember why this is in timeout, but it was in the original code 
    setTimeout(() => {
      microsoftTeams.app.initialize();
      microsoftTeams.app.notifyAppLoaded();
      microsoftTeams.app.notifySuccess();
      microsoftTeams.app.getContext().then((ctx:any)=>{log("Teams App Initialized Context", ctx.app.host)});
      
    }, 500);*/
    setTimeout(() => {
      microsoftTeams.app.initialize();
      microsoftTeams.app.notifyAppLoaded();
      microsoftTeams.app.notifySuccess();
      try
      {
        microsoftTeams.app.getContext().then(
          (ctx:any)=>
            {
              try
              {
                log("Teams App Initialized Context", ctx.app.host);
                setHostname(ctx.app.host.name);
                setClientType(ctx.app.host.clientType);
                if (ctx.app.host.clientType == "android" || ctx.app.host.clientType == "ios")
                {
                  setIsMobile(true);
                } else
                {
                  setIsMobile(false);
                }
              } catch (ex)
              {
                log("Teams App Initialized Context Error Host does not exists", ex, ctx);
                setIsMobile(false);
              }
            });
      } catch (ex)
      {
        log("Teams App Initialized Context Error", ex);
        setIsMobile(false);
      }
      
    }, 500);
    
  }, []);
  const  licensehandler = async(licensed:boolean, lorgid:string, lusermail:string, admin:boolean, nootheradmins:boolean) =>{
    if (nootheradmins)
    {
      setorgid(lorgid);
      setusermail(lusermail);
      setUseradmin(true);
      setUserlicensed(true);
      setLicensenavigated(true);
    } else 
    {
      setorgid(lorgid);
      setusermail(lusermail);
      setUseradmin(admin);
      if (licensed)
      {
        setUserlicensed(true);
      } else
      {
        setUserlicensed(false);
      }
    }
  }
  const  licensehandlermobile = async(licensed:boolean, lorgid:string, lusermail:string, admin:boolean, nootheradmins:boolean) =>{
    setorgid(lorgid);
    setusermail(lusermail);
    setUseradmin(false);
    setUserlicensed(true);
    setLicensenavigated(false);
    
  }

  return (
    <div>
      {!navigatedToPlan && useradmin && <div style={{position:'absolute', top:'2rem', right:'8rem'}}>
          <Button className="subemailbtn" onClick={()=>{setLicensenavigated(!licensenavigated)}} content={licensenavigated?"Close":""} icon={<Tooltip content="Add or remove licenses license from users" trigger={<TeamCreateIcon />} />}  iconOnly={!licensenavigated} iconPosition="before" primary={!licensenavigated} style={{zIndex:999}}/>
        </div>}
      {userlicensed && !licensenavigated ?
        <>
       
          {!ismobile?
            <div className="welcome page">
              <div className="narrow page-padding">
                <PlannerPPM licensehandler={licensehandler} navigateToPlan={(n:boolean) => {setNavigatedToPlan(n)}} />
              </div>
            </div>
            :
            <div>
                {/*<div style={{position:'absolute', bottom:5, right:5}}>Mobile V17</div>*/}
                <div className="mobilenarrow mobile-page-padding">
                  <PlannerPPMMobile licensehandler={licensehandlermobile} />
                </div>
            </div>
          }
        </>
        : 
        (useradmin?
          <AssignLicenses orgid={orgid} usermail={usermail}  />
          :
          <h1 style={{textAlign:'center'}}>You do not have permissions to manage licenses, please ask your administrator to grant you access here</h1>
        )
      }{/*<span style={{opacity:0.4, fontSize:10, position:"absolute", right:20, bottom:20}}>Host: {hostname} {clientType}, user agent: {navigator.userAgent}, iframe: {window.self !== window.top?'yes':'no'}, ismobile:{ismobile}</span>*/}
    </div>
  );
}
