// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will initialize TeamsFx SDK and show `GetUserProfile` component after initialization.

import { Button, Checkbox, Dropdown, DropdownProps, Flex, Popup, Slider, Tooltip } from "@fluentui/react-northstar";
import { useTeamsFx, useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { start } from "repl";
import { Gantt, ViewMode } from "../../gantt";
import { arghlog, getCookie, getFromLocalCache, log, saveToLocalCache, setCookie, shhlog } from "../../helper";
import { PlannerGroupsSelector } from "./PlannerGroupsSelector";
import { AuthorizeUser } from "../../authentication/AuthorizeUser";
import { PortfolioOverview } from "./portfolio/PortfolioOverview";
import { ZoomControl } from "./ZoomControl";
import { GanttChatControls } from "./GanttChatControls";
import { PlanGanttMultiple } from "./PlanGanttMultiple";
import { GanttPlan, GanttTask, Person } from "../../../ganttmods/publictypes";
import { PepleGraph } from "../PeopleGraph";
import { resolve } from "dns";


export function PlannerPPM(props: { licensehandler:Function, navigateToPlan:Function }) {
  // For usage of useTeamsFx(), please refer to: https://www.npmjs.com/package/@microsoft/teamsfx-react#useTeamsfx.
  // You need to wait until `loading == false` to use TeamsFx SDK.
  const { loading, error, teamsfx } = useTeamsFx({
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
  });
  
  const [planids,setPlanids] = useState<string | null>(null);
  const [multipleplans,setMultipleplans] = useState<GanttPlan[] | null>(null);
  const [viewMode,setViewMode] = useState<ViewMode>(ViewMode.Year);
  const [zoomLevel,setZoomLevel] = useState<number>(80);
  const [showCompletedTasks,setShowCompletedTasks] = useState<boolean>(true);
  const [authNeeded,setAuthNeeded] = useState<boolean>(false);
  const [authFailedMessage,setAuthFailedMessage] = useState<string>("");
  const [viewstart,setViewstart] = useState<Date|undefined>(undefined);
  const [viewend,setViewend] = useState<Date|undefined>(undefined);
  const [showPeople,setShowPeople] = useState<boolean>(false);
  const [people,setPeople] = useState<Person[] | null>(null);
  const [getTasksForPrint, setGetTasksForPrint] = useState<Function | null>(null);
  
  
  useEffect(()=>
  {
    log('Plan(s) Selected', multipleplans);
  }, [multipleplans]);
  const planloaded = (start:Date, end:Date) =>
  {
    if (multipleplans != null)
    {
      setViewstart(start);
      setViewend(end); 
    }
    
  }
  const periodchanged = (period:ViewMode)=>
  {
    shhlog('Period View Changed',period);
    setViewMode(period);
  }
  const zoomchanged = (zoom:number)=>
  {
    shhlog('Zoom Changed', zoom);
    setZoomLevel(zoom);
  }
  const askforpermissions = ():void =>
  {
    setAuthNeeded(true);
    setShowPeople(true);
    return;
  }
  return (
    <div>
      {!loading && error && (
        <div className="error">Failed init TeamsFx. Please try again later.</div>
      )}
      {!authNeeded  &&
        <>
            {/*false && <PepleGraph teamsfx={teamsfx} askforpermissions={showPeople} 
              peopleloaded=
              {
                (people:Person[])=>
                  {
                    setPeople(people == null?[]:people);
                    setCookie('peoplepermissionsgranted', 'yes', 365*5);
                  }
              } />
            */}
            <Flex space="between">
              {/*<PortfolioOverview planids={[]} />*/}
            {!showPeople && !loading && teamsfx &&
              <PlannerGroupsSelector 
                teamsfx={teamsfx} 
                /*planselected={(plan:GanttPlan|null, closeing:boolean):void=>
                  {
                    if (closeing) 
                    {
                      setPlanid(null);
                      setViewstart(undefined);
                      setViewend(undefined);
                      setMultipleplans(null);
                    }
                    setPlanid(null);
                    setPlanname(null);
                    if (plan != null && plan != undefined)
                    {
                      setPlanid(plan.planid);
                      setPlanname(plan.planName);
                      props.navigateToPlan(true);
                    } else 
                    {
                      props.navigateToPlan(false);
                    }
                    
                  }} */
                multipleplansselected={(plans:GanttPlan[]|null, closeing:boolean)=>
                  {
                    log("multipleplansselected", plans, closeing);
                    if (closeing) 
                    {
                      setViewstart(undefined);setViewend(undefined);
                    }
                    setMultipleplans(plans);
                    let planids:string|null = null;
                    if (plans != null)
                    {
                      planids = plans.map((p)=>p.planid).join(',');
                    }
                    setPlanids(planids);
                    setShowCompletedTasks(true);
                    props.navigateToPlan(plans==null?false:true);
                  }} 
                authorizeUser={(msg:string)=>{setAuthFailedMessage(msg);setAuthNeeded(true);}}
                mobile={false}
                nolicense={props.licensehandler}
                people={people} 
              />}
              {(planids != null || multipleplans != null ) && 
                <GanttChatControls getTasksForPrint={getTasksForPrint} periodvalue={ViewMode.Year} onChangeZoom={zoomchanged} onChangePeriod={periodchanged} viewstart={viewstart} viewend={viewend} planid={planids as string}  showCompletedTasksDefaultValue={showCompletedTasks} onShowCompletedTasksChanged={(v:any)=>{setShowCompletedTasks(!showCompletedTasks);}} teamsfx={teamsfx} plans={multipleplans?multipleplans:[]} people={people} viewMode={viewMode} />
              }
            </Flex>
          
          
          {multipleplans != null && !showPeople && 
            <>   
              <PlanGanttMultiple teamsfx={teamsfx} plans={multipleplans} viewMode={viewMode} viewModeZoomLevel={zoomLevel} planloaded={planloaded} showCompletedTasks={showCompletedTasks} hidechart={false} askforpermissions={askforpermissions} people={people} peopleLoaded={(p)=>{setPeople(p);}} getTasksForPrintReady={(getTasksForPrint:Function)=>{setGetTasksForPrint(( ) => getTasksForPrint);}} />
            </>
          }
        </>
      }
      {authNeeded &&
        
        <AuthorizeUser teamsfx={teamsfx} loggedinComplete={(scopes:string[])=>{
          setAuthNeeded(false);
        }} reasonmessage={authFailedMessage}  />
        
      }
    </div>
  );
}
