import { GanttTask, Label } from "../ganttmods/publictypes";
import { Task } from "./gantt";

export function log(msg:string, ...optionalParams: any[]): void {
  try
  {
    if (optionalParams != undefined && optionalParams != null && optionalParams.length > 0)
    {
      console.log("PlannerGantt: " + msg, optionalParams);
    } else
    {
      console.log("PlannerGantt: " + msg);
    }
  } catch   (ex)
  {
    console.log("PlannerGantt: Log Error");
    console.log("PlannerGantt: Log Error", ex);
  }
}
export function shhlog(msg:string, ...optionalParams: any[]): void {
  try
  {
    if (optionalParams != undefined && optionalParams != null && optionalParams.length > 0)
    {
      console.debug("PlannerGantt: " + msg, optionalParams);
    } else
    {
      console.debug("PlannerGantt: " + msg);
    }
  } catch   (ex)
  {
    console.debug("PlannerGantt: Log Error");
    console.debug("PlannerGantt: Log Error", ex);
  }
}
export function arghlog(msg:string, ...optionalParams: any[]): void {
  try
  {
    if (optionalParams != undefined && optionalParams != null && optionalParams.length > 0)
    {
      console.error("PlannerGantt: " + msg, optionalParams);
    } else
    {
      console.error("PlannerGantt: " + msg);
    }
  } catch   (ex)
  {
    console.log("PlannerGantt: Log Error");
    console.log("PlannerGantt: Log Error", ex);
  }
}
export function getStartEndDateForProject(tasks: Task[], projectId: string) {
    const projectTasks = tasks.filter(t => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
  
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }
  export function getStartEndDateForPlan(tasks: GanttTask[], planid: string) {
    const planTasks = tasks.filter(t => t.planid === planid && t.id != planid);
    let start:Date = planTasks[0].start;
    let end:Date = planTasks[0].end;
  
    for (let i = 0; i < planTasks.length; i++) {
      const task = planTasks[i];
      if (!task.isplan && task.type != "project")
      {
        if (start.getTime() > task.start.getTime()) {
          start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
          end = task.end;
        }
      }
    }
    return [start, end];
  }
  export function textHashCode(str:string):number {
    var hash = 0, i, chr, len;
    if (str.length === 0) return hash;
    for (i = 0, len = str.length; i < len; i++) {
      chr   = str.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
  export function randomNumberFromText(str:string, max:number):number {
    let txtnum = textHashCode(str);
    let rnum  = txtnum % max;
    if (rnum < 0 )
    {
      rnum = rnum * -1;
    }
    return rnum;
  };
  export function randomText():string {
    let result = '';
    let length = 10;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  export function setCookie(cname:String, cvalue:NamedCurve, cdays:number):void {
    
    const d = new Date();
    d.setTime(d.getTime() + (cdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export function getCookie(cname:string):string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  export function saveToLocalCache(key:string, obj:any): void {
    //setCookie(key, JSON.stringify(obj), 90);
    localStorage.setItem(key, JSON.stringify(obj));
  }
  export function getFromLocalCache(key:string): any {
    let obj = localStorage.getItem(key);
    if (obj == undefined ||obj == null || obj == "")
    {return null;}
    return JSON.parse(obj);
  }
  export function ordinalCompareStr1BeforeStr2(str1:string, str2:string): number 
  {
    /*for (let i=0;i<str1.length;i++)
    {
      if ((str2.length-1) == i)
      {
        return 1;
      }
      if ((str1.length-1) == i)
      {
        return -1;
      }
      if (str1[i] === str2[i])
      {
        //continue;
      }
      else
      {
        return str1.charCodeAt(i) - str2.charCodeAt(i);
      } 
    }
    log('Ordinal Compare Failed, strings identical', str1, str2);
    return -1;*/
    /*let str1ord = 0;
    for (let i=0;i<str1.length;i++)
    {
      str1ord = str1ord + str1.charCodeAt(i)
    }
    let str2ord = 0;
    for (let i=0;i<str2.length;i++)
    {
      str2ord = str2ord + str2.charCodeAt(i)
    }*/
    let str1ord = 0;  
    let str2ord = 0;
    let i=0;
    while (i<str1.length && i<str2.length)
    {
      str1ord = str1ord + str1.charCodeAt(i);
      str2ord = str2ord + str2.charCodeAt(i);
      if (str2.charCodeAt(i) != str1.charCodeAt(i))
      {break;}
      i++;
    }
    if (str1ord-str2ord == 0)
    {
      log("Only one orderHint is longer than the other, else identical. Possible error", str1, str2);
      if (i<str1.length)
      {
        str1ord = str1ord + str1.charCodeAt(i);
      } else if (i<str2.length)
      {
        str2ord = str2ord + str2.charCodeAt(i);
      }
    }
    return str1ord-str2ord;
   }
   export function getTaskBefore(tsk:Task, alltasks:Task[]): Task 
  {
    let taskbefore:Task | undefined =  undefined;
    for (let i=0; i<alltasks.length;i++)
    {
      if (alltasks[i].type == "task" && alltasks[i].project == tsk.project)
      {
        
        if ((taskbefore == undefined || (alltasks[i]?.displayOrder as number) > (taskbefore?.displayOrder as number) )
          && (alltasks[i]?.displayOrder as number) < (tsk?.displayOrder as number))
        {
          taskbefore = alltasks[i];
        }
      }
    }
    return taskbefore as Task;
  }
  export function getTaskAfter(tsk:Task, alltasks:Task[]): Task 
  {
    let taskafter:Task | undefined =  undefined;
    for (let i=0; i<alltasks.length;i++)
    {
      if (alltasks[i].type == "task" && alltasks[i].project == tsk.project)
      {
        
        if ((taskafter == undefined || (alltasks[i]?.displayOrder as number) < (taskafter?.displayOrder as number) )
          && (alltasks[i]?.displayOrder as number) > (tsk?.displayOrder as number))
        {
          taskafter = alltasks[i];
        }
      }
    }
    return taskafter as Task;
  }
  export function getTaskProject(tsk:Task, alltasks:Task[]): Task 
  {
    let taskproject:Task |undefined =  undefined;
    for (let i=0; i<alltasks.length;i++)
    {
      if (alltasks[i].type == "project" && alltasks[i].id == tsk.project)
      {
        taskproject = alltasks[i];
        break;
      }
    }
    return taskproject as Task;
  }
  export function getProjectAfter(tsk:Task, alltasks:Task[]): Task 
  {
    let taskprojectafter:Task | undefined =  undefined;
    for (let i=0; i<alltasks.length;i++)
    {
      if (alltasks[i].type == "project")
      {
        
        if ((taskprojectafter == undefined || (alltasks[i]?.displayOrder as number) < (taskprojectafter?.displayOrder as number) )
          && (alltasks[i]?.displayOrder as number) > (tsk?.displayOrder as number))
        {
          taskprojectafter = alltasks[i];
        }
      }
    }
    return taskprojectafter as Task;
  }
  export function getNextTaskOrProject(tsk:Task, alltasks:Task[]): Task 
  {
    let nxttsk:Task | undefined =  undefined;
    for (let i=0; i<alltasks.length;i++)
    {
      
        
        if ((nxttsk == undefined || (alltasks[i]?.displayOrder as number) < (nxttsk?.displayOrder as number) )
          && (alltasks[i]?.displayOrder as number) > (tsk?.displayOrder as number))
        {
          nxttsk = alltasks[i];
        }
      
    }
    return nxttsk as Task;
  }

  // The debounce function receives our function as a parameter
export const debounce = (fn:any) => {

  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame:any;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params:any) => {
    
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) { 
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      
      // Call our function and pass any params we received
      fn(...params);
    });

  } 
};
export function addDaysToDate(date:Date, days:any) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
export function getMinDate(dates:GanttTask[]) {
  let rtnDate = new Date(2025, 1, 2);
  for (let i=0; i<dates.length; i++)
  {
    if (dates[i].start != null && new Date(dates[i].start).getTime() < rtnDate.getTime())
    {
      rtnDate = new Date(dates[i].start);
    }
  }
  return rtnDate;
};
export function getMaxDate(dates:GanttTask[]) {
  let rtnDate = new Date(1970, 1, 2);
  for (let i=0; i<dates.length; i++)
  {
    if (dates[i].end != null && new Date(dates[i].end).getTime() > rtnDate.getTime())
    {
      rtnDate = new Date(dates[i].end);
    }
  }
  return rtnDate;
};

export function getQueryParams(name:string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isemail(emailstring:string):boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(emailstring)) 
  {
    log('Valid email address');
    return true
  } else {
    log('Invalid email address', emailstring);
    return false;
  }
}

export function getFirstDate(tasks:any[]):Date {
  let today:Date = new Date();
  let todaytask:any = {startDateTime:today};
  let start = tasks.reduce(function (a:any, b:any) 
  {
    if ((a.startDateTime == null || a.startDateTime == undefined) && (b.startDateTime == null || b.startDateTime == undefined))
    {return todaytask;}
    if (a.startDateTime == null || a.startDateTime == undefined)
    {
      if (new Date(b.startDateTime) > today)
      {
        return todaytask;
      }
      return b;
    }
    if (b.startDateTime == null || b.startDateTime == undefined)
    {
      if (new Date(a.startDateTime) > today)
      {
        return todaytask;
      }
      return a;
    }
     return new Date(a.startDateTime) < new Date(b.startDateTime) ? a : b; 
  }).startDateTime;
  return start;
}
export function getLastDate(tasks:any[]):Date {
  let today:Date = new Date();
  let todaytask:any = {dueDateTime:today};
  let end = tasks.reduce(function (a:any, b:any) 
  {
    if ((a.dueDateTime == null || a.dueDateTime == undefined) && (b.dueDateTime == null || b.dueDateTime == undefined))
    {return todaytask;}
    if (a.dueDateTime == null || a.dueDateTime == undefined)
    {
      if (new Date(b.dueDateTime) < today)
      {
        return todaytask;
      }
      return b;
    }
    if (b.dueDateTime == null || b.dueDateTime == undefined)
    {
      if (new Date(a.dueDateTime) < today)
      {
        return todaytask;
      }
      return a;
    }
    return new Date(a.dueDateTime) > new Date(b.dueDateTime) ? a : b; 
  }).dueDateTime;
  return end;
}
export function uniqStringArray(arr:string[]):string[] {
  let seen:string[] = [];
  return arr.filter((item, index) => {
      return arr.indexOf(item) === index;
  });
}
export function getDependenciesFromDescription(description:string ):string[] {
  if (description == null || description == undefined || description == "")
  {return [];}
  var match =  description.match(/Dependencies: '([a-zA-Z0-9_-]*(?:,\s*[a-zA-Z0-9_-]+)*)'/g);
  if (match == null || match.length == 0)
  {return [];}
  let sdependencies = match[0].replace("Dependencies: '", "").replace("'","");
  if (sdependencies == null || sdependencies == undefined || sdependencies == "")
  {return [];}
  return uniqStringArray(sdependencies.split(","));
}
export function getDependenciesFromAttachments(references:any ):string[] {
  if (references == null || references == undefined || references == "" || Object.keys(references).length === 0)
  {return [];}
  let sdependencies:string[] = [];
 
  for (const key in references) 
  {
    const value = references[key];
    if (value.type == "Other")
    {
      let dependencyobj:string = key;
      let taskidextract = dependencyobj.match(/&taskId=([^&]+)/);
      if (taskidextract) 
      {
        const taskId = taskidextract[0].substring(8, taskidextract[0].length); // Extract the matched taskId
        sdependencies.push(taskId);
      } else {
        //not a dependency
      }
      
    }
    
  }
  
  return sdependencies;
}
export function getDependencyKeyFromId(references:any, taskid:string):string {
  if (references == null || references == undefined || references == "" || Object.keys(references).length === 0)
  {return "";}
  let sdependencies:string[] = [];
 
  for (const key in references) 
  {
    const value = references[key];
    if (value.type == "Other")
    {
      let dependencyobj:string = key;
      let taskidextract = dependencyobj.match(/&taskId=([^&]+)/);
      if (taskidextract) 
      {
        const extaskId = taskidextract[0].substring(8, taskidextract[0].length); // Extract the matched taskId
        if (extaskId == taskid)
        {
          return key;
        }
      } else {
        //not a dependency
      }
      
    }
    
  }
  return "";
}
export function replaceDependenciesToDescription(desc:string, newdependencies:string[] ):string 
{
  let dependencies = newdependencies.filter((item, index) => {return item != "" && item != null && item != undefined;});
  let description = ""; //desc.replaceAll('\r\n\r\n', '\r\n').trimStart().trimEnd();
  description = desc.trimStart().trimEnd().replaceAll('\r\n\r\n', '\r\n').replaceAll('\r\n', '\n');
  if (dependencies == null || dependencies == undefined || dependencies.length == 0)
  {
    if (description == "")
    { return "";}
    var match =  description.match(/Dependencies: '([a-zA-Z0-9_-]*(?:,\s*[a-zA-Z0-9_-]+)*)'/g);
    if (match != null && match.length > 0)
    {
      description = description.replace(match[0], "").replaceAll("Dependencies: ''", "");
      return description;
    } else
    {return description;}
  }
  if (description == null || description == undefined || description == "")
  {
    description = "\n" + "Dependencies: '" + dependencies.join(",") + "'";
  } else
  {
    var match =  description.match(/Dependencies: '([a-zA-Z0-9_-]*(?:,\s*[a-zA-Z0-9_-]+)*)'/g);
    if (match != null && match.length > 0)
    {
      description = description.replace(match[0], "Dependencies: '" + dependencies.join(",") + "'");
    } else
    {
      description = description +"\n" + "Dependencies: '" + dependencies.join(",") + "'";
    }    
  }
  return description;
}

export const PlanColors:string[] = 
[
'#23874B',
'#8151FD',
'#D50AC6',
'#EC0E3F',
'#2B5797',
'#99B433',
'#00ABA9',
'#FF0097',
'#B91D47',
'#00A300',
'#7E3878',
'#DA532C',
'#6BA5E7',
'#E773BD',
'#2D89EF',
'#4E5284',
'#FF9900',
'#1500FF',
'#6BA5E7',
'#23874B'
];

export function cutoffText(txt:string, len:number):string 
{
    if (txt.length <= len)
    {return txt;}
    let newtxt = txt.substring(0, len-3) + "...";
    return newtxt;
}
export function isColorDark(color: string): boolean {
  // Remove any leading "#" from the color string
  const match = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(\.\d+)?))?\)/.exec(color);

  if (!match) {
    throw new Error("Invalid color format");
  }

  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);

  // Calculate the brightness or luminance using the formula
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;


  // Choose a threshold to determine if it's light or dark
  // You can adjust this threshold as needed
  const threshold = 0.5;

  // Compare the luminance to the threshold
  if (luminance < threshold) {
    return false;
  } else {
    return true;
  }
}
export function getLabelsforplan(categoryDescriptionsDictionary:any): Label[] 
{
  
    let categories:any = [];
    for (let key in categoryDescriptionsDictionary)
    {
      categories[key] = true;
    }
    let labels = getLabelsfortask(categories, categoryDescriptionsDictionary);

  
  return labels;
}
export function getLabelsfortask(appliedCategories: any, categoryDescriptionsDictionary:any): Label[] 
{
  let labelsfortask:Label[] = [];
  for (let key in appliedCategories)
  {
    if (appliedCategories[key] == true)
    {
      let color = "rgb(255, 255, 255)";
      let backgroundColor = "rgb(57, 65, 61)";
      let categoryLabel = "Dark grey";
      
      switch (key) {
        case "category1":
          color = "rgb(172, 45, 126)";
          backgroundColor = "rgb(251, 221, 240)";
          categoryLabel = "Pink";
          break;
        case "category2":
          color = "rgb(117, 11, 28)";
          backgroundColor = "rgb(233, 199, 205)";
          categoryLabel = "Red";
          break;
        case "category3":
          color = "rgb(109, 87, 0)";
          backgroundColor = "rgb(245, 237, 206)";
          categoryLabel = "Yellow";
          break;
        case "category4":
          color = "rgb(56, 99, 4)";
          backgroundColor = "rgb(219, 235, 199)";
          categoryLabel = "Green";
          break;
        case "category5":
          color = "rgb(0, 91, 161)";
          backgroundColor = "rgb(208, 231, 248)";
          categoryLabel = "Blue";
          break;
        case "category6":
          color = "rgb(64, 27, 108)";
          backgroundColor = "rgb(216, 204, 231)";
          categoryLabel = "Purple";
          break;
        case "category7":
          color = "rgb(167, 65, 9)";
          backgroundColor = "rgb(241, 217, 204)";
          categoryLabel = "Bronze";
          break;
        case "category8":
          color = "rgb(64, 96, 20)";
          backgroundColor = "rgb(229, 242, 211)";
          categoryLabel = "Lime";
          break;
        case "category9":
          color = "rgb(0, 102, 102)";
          backgroundColor = "rgb(194, 231, 231)";
          categoryLabel = "Aqua";
          break;
        case "category10":
          color = "rgb(93, 90, 88)";
          backgroundColor = "rgb(229, 228, 227)";
          categoryLabel = "Grey";
          break;
        case "category11":
          color = "rgb(75, 83, 86)";
          backgroundColor = "rgb(234, 238, 239)";
          categoryLabel = "Silver";
          break;
        case "category12":
          color = "rgb(77, 41, 28)";
          backgroundColor = "rgb(226, 209, 203)";
          categoryLabel = "Brown";
          break;
        case "category13":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(197, 15, 31)";
          categoryLabel = "Cranberry";
          break;
        case "category14":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(218, 59, 1)";
          categoryLabel = "Orange";
          break;
        case "category15":
          color = "rgb(0, 0, 0)";
          backgroundColor = "rgb(255, 140, 0)";
          categoryLabel = "Peach";
          break;
        case "category16":
          color = "rgb(0, 0, 0)";
          backgroundColor = "rgb(234, 163, 0)";
          categoryLabel = "Marigold";
          break;
        case "category17":
          color = "rgb(0, 0, 0)";
          backgroundColor = "rgb(19, 161, 14)";
          categoryLabel = "Light green";
          break;
        case "category18":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(11, 106, 11)";
          categoryLabel = "Dark green";
          break;  
        case "category19":
          color = "rgb(0, 0, 0)";
          backgroundColor = "rgb(0, 183, 195)";
          categoryLabel = "Teal";
          break;
        case "category20":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(0, 120, 212)";
          categoryLabel = "Light blue";
          break;  
        case "category21":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(0, 57, 102)";
          categoryLabel = "Dark blue";
          break;  
        case "category22":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(113, 96, 235)";
          categoryLabel = "Lavender";
          break;
        case "category23":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(119, 0, 77)";
          categoryLabel = "Plum";
          break;
        case "category24":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(122, 117, 116)";
          categoryLabel = "Light grey";
          break;
        case "category25":
          color = "rgb(255, 255, 255)";
          backgroundColor = "rgb(57, 65, 70)";
          categoryLabel = "Dark grey";
          break;
      }               
      
      if (key in categoryDescriptionsDictionary && categoryDescriptionsDictionary[key] != null && categoryDescriptionsDictionary[key] != "" && categoryDescriptionsDictionary[key] != undefined)
      {
        categoryLabel = categoryDescriptionsDictionary[key];
      }
      let newlabel:Label = {key:key, header:categoryLabel, categoryColor:color, categoryBackgroundColor:backgroundColor};
      labelsfortask.push(newlabel);
    }
  }

  
  return labelsfortask;
}
export function countCapitalLetters(str:String):number {
  // Match all capital letters (A-Z) in the string
  const matches = str.match(/[A-Z]/g);
  
  // If there are no matches, return 0
  if (matches === null) {
      return 0;
  }
  
  // Return the number of matches
  return matches.length;
}


export function getChartColors():string[] {
  //Not Started, In Progress, Late, Completed
  //return ['#26A0FC', '#71D103','#FF6D83',  '#D2D3D4'];
  //return ['#F5E960', '#55D6C2','#F49097',  '#F2F5FF', '#A0A0A0'];
  return ['#F5E960', '#55D6C2','#F49097',  '#DBD3D8', '#f5f0b5'];
}
export function lightenHexColor(hex:string, percent:number): string {
  // Remove the leading # if present
  hex = hex.replace(/^#/, '');

  // Parse the hex string into RGB components
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Increase each component by the specified percentage
  r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
  g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
  b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

  // Convert the components back to a hex string
  const rHex: string = r.toString(16).padStart(2, '0');
  const gHex: string = g.toString(16).padStart(2, '0');
  const bHex: string = b.toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}`;
}
export enum TaskStatus {NotStarted, InProgress, Late, Completed};
export function getTaskStatus(progress:number, enddate:Date): TaskStatus {
  let today = new Date();
  if (progress == 100)
  {
      return TaskStatus.Completed;
  }
  else if (progress > 0 && progress < 100 && new Date(enddate).getTime() > today.getTime())
  {
      return TaskStatus.InProgress;
  }
  else if (progress == 0 && new Date(enddate).getTime() > today.getTime())
  {
      return TaskStatus.NotStarted;
  }
  else if (progress != 100 && new Date(enddate).getTime() <= today.getTime())
  {
      return TaskStatus.Late;
  }
  return TaskStatus.NotStarted;
}
export function getDurationInDaysExcludingWeekends(startd:Date, endd:Date):number {
  // To calculate the time difference of two dates and exclude weekends
  let startDate = new Date(startd);
  let endDate = new Date(endd);
  if (startDate > endDate) [startDate, endDate] = [endDate, startDate];

  // Calculate the total difference in days
  const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1;
  
  // Calculate the number of complete weeks and extra days
  const fullWeeks = Math.floor(totalDays / 7);
  const extraDays = totalDays % 7;

  // Get the day of the week for start and end dates
  const startDay = startDate.getDay();
  const endDay = (startDay + extraDays - 1) % 7;

  // Calculate the number of weekend days in the extra days
  let weekendDays = 0;
  if (startDay <= endDay) {
    // Weekend within the same week
    if (startDay <= 5 && endDay >= 6) weekendDays = 2; // Both Saturday and Sunday
    else if (startDay <= 5 && endDay >= 5) weekendDays = 1; // Only Saturday
  } else {
    // Weekend wrapped to the next week
    if (startDay <= 5 || endDay >= 6) weekendDays = 2;
    else weekendDays = 1;
  }

  // Total weekend days = weekends from full weeks + weekend days in extra days
  const totalWeekendDays = fullWeeks * 2 + weekendDays;

  // Subtract weekends from the total days
  return totalDays - totalWeekendDays;
}