// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.


import { useEffect, useRef, useState } from "react";
import { PlansActiveTasksPieChart } from "./PlansActiveTasksPieChart";
import { PlanStackedPlans } from "./PlanStackedPlans";
import { GanttPlan, GanttTask, Person } from "../../../../ganttmods/publictypes";
import { getFromLocalCache, log, saveToLocalCache } from "../../../helper";
import { PeopleTasks } from "./PeopleTasks";
import { PepleGraph } from "../../PeopleGraph";
import { TeamsFx } from "@microsoft/teamsfx";
import { UnassignedTasks } from "./UnassignedTasks";
import { ArrowLeftIcon, Button, Dropdown, DropdownProps, Flex, Loader } from "@fluentui/react-northstar";

import { Line } from '@nivo/line';
import { PlanTasksSunburst } from "./PlanTasksSunburst";
import { PlanCard } from "../PlanCard";

export function PlanDetails(props: {plan:GanttPlan, people:Person[], width:number,  height:number , teamsfx?: TeamsFx, close:()=>void}) 
{
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
    };
  useEffect(()=>{
    log('PlanDetails', props.plan);
  }, [props.plan]);
  
  

  return (
    <>
      <Flex gap="gap.small" padding="padding.medium" style={{ width:'100vw'}} hAlign="start" vAlign="center">
          <PlanCard title={props.plan.planName} subtitle={props.plan.ownerName} plan={props.plan} closeBtn={true} onSelect={()=>{props.close()}} ></PlanCard>
      </Flex>
      
      <Flex gap="gap.small" padding="padding.medium" style={{overflow:'hidden', width:'100vw', height:'calc(85vh - 70px)'}}>
        
        <Flex.Item size="size.medium">
          <PlanTasksSunburst plan={props.plan} people={props.people} width={props.width*0.4} height={props.height*0.8} />
        </Flex.Item>
        <Flex.Item size="size.medium">
          <PeopleTasks dashboardplans={[props.plan]} people={props.people} width={props.width*0.45} height={props.height*0.8} />
        </Flex.Item>

        <Flex.Item size="size.small">
          <UnassignedTasks dashboardplans={[props.plan]} people={props.people} width={props.width*0.1} height={props.height*0.8} />
        </Flex.Item>
      </Flex>
    </>
  );
}
